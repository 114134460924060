// store/modules/notifications.js
import { getNotificacoes, setNotificationAsRead } from "../../services/historico-notificacoes"; // Assuming getNotificacoes is an API function to fetch notifications
import { currentUser } from "../../constants/config";

const state = {
  historicNotificationList: [],
  newNotificationsList: [],
};

const mutations = {
  setHistoricNotifications(state, notifications) {
    state.historicNotificationList = notifications;
  },
  setNewNotifications(state, notifications) {
    state.newNotificationsList = notifications;
  },
};

const actions = {
  async fetchNotifications({ commit }) {
    try {
      const response = await getNotificacoes();
      const historicNotifications = [];
      const newNotifications = [];

      response.forEach((notification) => {
        let wasViewed = false;
        if (
          notification["notificacao_lida_grupos"]
            .split("; ")
            .includes(String(currentUser.grupo_id)) ||
          notification["notificacao_lida_grupos"].toUpperCase() === "TODOS"
        ) {
          wasViewed = true;
        }

        if (wasViewed) {
          historicNotifications.push(notification);
        } else {
          newNotifications.push(notification);
        }
      });

      commit("setHistoricNotifications", historicNotifications);
      commit("setNewNotifications", newNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  },

  async setNotificationAsRead({ commit }, notificationId) {

    try {
      const response = await setNotificationAsRead(notificationId);

      let notificationRead = state.newNotificationsList.find(
        (obj) => obj.id === notificationId
      );
      commit("setHistoricNotifications", [
        ...state.historicNotificationList,
        notificationRead,
      ]);
      let newArray = state.newNotificationsList.filter(
        (obj) => obj.id !== notificationId
      );
      commit("setNewNotifications", newArray);

    } catch (error) {
      console.error("Error updating notification:", error);
    }
  }
};

const getters = {
  historicNotifications: (state) => state.historicNotificationList,
  newNotifications: (state) => state.newNotificationsList,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
