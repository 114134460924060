import Vue from 'vue'
import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import user from './modules/user'
import notifications from "./modules/notifications";
import flashlink from './modules/flashlink'
import sales from './modules/sales'
import extracts from './modules/extracts'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    estabelecimento:'Todos',
    estabelecimentosEC:[],
    visible:true,
  },
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload
      localStorage.setItem('currentLanguage', payload)
    },
    mudarEstabelecimento(state, value){
      state.estabelecimento = value
    },
    filtro(state, rota) {
      let arr = ["/app/extrato", "/app/dashboard/meusDados"];
      if (arr.indexOf(rota) > -1) {
        state.visible = false;
      } else {
        state.visible = true;
      }
    },
    getEstabelecimentos(state, rota) {
      let arr = ['/app/extrato', '/app/simulacao', '/app/logistica', '/app/contestacao-vendas', '/app/dashboard/meusDados', '/app/dashboard/estabelecimentos_associados']
      let indice = arr.indexOf(rota)
      state.estabelecimentosEC = [];
      let getListaEstabelecimentos = [];
      getListaEstabelecimentos = JSON.parse(sessionStorage.getItem('currentUser')).estabelecimentos
      state.estabelecimentosEC.push('Todos')
      for (let i = 0; i < getListaEstabelecimentos.length; i++) {
        if (getListaEstabelecimentos[i].fantasia != null && getListaEstabelecimentos[i].fantasia != '') {
          state.estabelecimentosEC.push(getListaEstabelecimentos[i].cpf_cnpj+' - '+ getListaEstabelecimentos[i].apelido)
        } else {
          state.estabelecimentosEC.push(getListaEstabelecimentos[i].cpf_cnpj+' - '+ getListaEstabelecimentos[i].fantasia)
        }
      }

      let indice2 = state.estabelecimentosEC.indexOf('Todos')

      if (indice > -1 && indice2 > -1) {
        state.estabelecimentosEC.splice(indice2, 1)
        state.estabelecimento = state.estabelecimentosEC[0]
      }
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    menu,
    user,
    notifications,
    flashlink,
    sales,
    extracts
  },
});
