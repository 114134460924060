export const generateFilterQuery = (filters) => {
  let filter = "";
  let mes_inicial = filters.startDate.getMonth() + 1;
  let mes_final = filters.endDate.getMonth() + 1;
  filter = `?utf8=✓&data_inicial=${filters.startDate.getDate()}%2F${mes_inicial}%2F${filters.startDate.getFullYear()}`;
  filter += `&data_final=${filters.endDate.getDate()}%2F${mes_final}%2F${filters.endDate.getFullYear()}`;
  filter += `&tipo_lancamento=${filters.tipo_lancamento.code}`;
  filter += `&decimals=` + 4;

  return filter
}