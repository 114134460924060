import "./assets/css/vendor/dropzone.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import 'video.js/dist/video-js.css'

/* if you want single color remove this section:multicolor */
var color = 'light.blue';

if (localStorage.getItem("themeColor")) {
  color = localStorage.getItem("themeColor");
}
let render = () => {
  import("./assets/css/sass/themes/piaf." + color + ".scss").then(() =>
    require("./main")
  );
};

render();
/* if you want single color remove this section:multicolor */
