import {
  currentUser,
  historicoNotificacoes,
  historicoNotificacoesUpdate,
} from "../constants/config";
import axios from "axios";

export const getNotificacoes = async() => {
  let config = { headers: { Authorization: currentUser.token } };
  if (!currentUser || !currentUser.token) {
    throw new Error("User token is not available");
  }

  const response = await axios.get(
    historicoNotificacoes + currentUser.grupo_id,
    config
  );
  return response.data.data;
};

export const setNotificationAsRead = async (notificationId) => {

  let config = { headers: { Authorization: currentUser.token } };

  const response = await axios.post(
    historicoNotificacoesUpdate + currentUser.grupo_id + '/' + notificationId,
    null,
    config
  );
  return response.data.data;
};
