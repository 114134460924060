<template>
  <div class="h-100">
    <chat/>
    <router-view/>
  </div>
</template>

<script>
import {getDirection} from "./utils";
import Chat from "./components/Common/Chat";

export default {
  components:{
    "chat": Chat
  },
  beforeMount() {
    if (JSON.parse(sessionStorage.getItem('currentUser')) == undefined) {
      this.$router.push('../user/login')
    } else if (JSON.parse(sessionStorage.getItem('currentUser')).role == 'SELLER') {
      this.$router.push('../app/flashlink')
    } else {
      this.$router.push('../app/dashboard')
    }

    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.dir = "rtl";
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.dir = "ltr";
      document.body.classList.remove("rtl");
    }
  },
  beforeUpdate() {
    let session = JSON.parse(sessionStorage.getItem('currentUser'))
    if (session) {
      let dt_now = new Date()
      let result = (dt_now.getTime() / 1000) - session.start_session
      if (result >= 18000) {
        alert('Tempo da sessão expirado')
        sessionStorage.removeItem('currentUser')
        this.$router.push('../user/login').catch(err => {
        })
        window.location.reload()
      } else {
        let date = new Date(session.start_session * 1000)
      }
    }
  },
  mounted() {
    document.body.classList.add('rounded');
    if (process.env.NODE_ENV !== 'development' && typeof (window.Conpass.user) === "undefined") {
      this.dispararCompass(JSON.parse(sessionStorage.getItem('currentUser')).nome, JSON.parse(sessionStorage.getItem('currentUser')).email, JSON.parse(sessionStorage.getItem('currentUser')).id, JSON.parse(sessionStorage.getItem('currentUser')).razao_social)
    }
  },
  methods: {
    dispararCompass(nome, email, id, razao_social) {
      var count = 0;
      var ConpassInterval = window.setInterval(function () {
        if (window.Conpass) {
          window.Conpass.init({
            name: nome,
            email: email,
            account_fields: {
              conpany_id: id,
              conpany: razao_social,
            }
          });
        }
        if (window.Conpass || count >= 100) clearInterval(ConpassInterval);
        count += 1;
      }, 100);
      // window.Conpass.debug();
    }
  }
};
</script>
<style>
/* IMPORTS STYLES COMPONENTS */
@import url("./assets/css/components/BBadge.css");
@import url("./assets/css/components/BButton.css");
@import url("./assets/css/components/BFormGroup.css");
@import url("./assets/css/components/BModal.css");
@import url("./assets/css/components/BPagination.css");
@import url("./assets/css/components/BTable.css");
@import url("./assets/css/components/BToast.css");
@import url("./assets/css/components/Notification.css");

/* IMPORTS STYLES VIEWS */
@import url("./assets/css/views/FlashlinkIndex.css");
@import url("./assets/css/views/Recebidos.css");
@import url("./assets/css/views/LinkBoleto.css");
@import url("./assets/css/views/Vendas.css");
</style>
