import {
  bandeira_master_const,
  bandeira_hiper_const,
  bandeira_amex_const,
  bandeira_elo_const,
  bandeira_dinner_const,
  bandeira_default_const,
  bandeira_visa_const,
} from "@/constants/config";

export const generateFilterQuery = (filters, estabelecimento) => {
  const startDate = `${filters.startDate.getDate()}/${
    filters.startDate.getMonth() + 1
  }/${filters.startDate.getFullYear()}`;
  const endDate = `${filters.endDate.getDate()}/${
    filters.endDate.getMonth() + 1
  }/${filters.endDate.getFullYear()}`;

  let filter = `?utf8=✓&data_inicial=${startDate}&data_final=${endDate}`;

  if (filters.terminal.indexOf(" - ") !== -1) {
    let numSerie = filters.terminal.split(" - ")[0]
    filter += addFilter(numSerie, "numero_serie");
  } else {
    filter += addFilter(filters.terminal, "numero_serie");
  }

  filter += addFilter(filters.saleType, "tipo_venda");
  filter += addFilter(filters.saleStatus, "status_venda");
  filter += addFilter(filters.creditCardFlag, "bandeira");
  filter += addFilter(estabelecimento.split(" - ")[0], "cpf_cnpj");
  filter += addFilter(filters.nsu, "nsu");

  if (filters.saleCapture !== 'Todos') {
    filter += `&captura=${filters.saleCapture.code}&decimals=4`;
  }

  filter += `&decimals=4`;

  return filter;
};

export const generateFilterQuerySalesTable = (filters, estabelecimento) => {
  const startDate = `${filters.startDate.getDate()}/${
    filters.startDate.getMonth() + 1
  }/${filters.startDate.getFullYear()}`;
  const endDate = `${filters.endDate.getDate()}/${
    filters.endDate.getMonth() + 1
  }/${filters.endDate.getFullYear()}`;

  let filter = `?utf8=✓&data_inicial=${startDate}&data_final=${endDate}`;
  filter += addFilter(filters.terminal, "numero_serie");
  filter += addFilter(filters.saleType, "tipo_venda");
  filter += addFilter(filters.saleStatus, "status_venda");
  filter += addFilter(filters.creditCardFlag.code, "bandeira");
  filter += addFilter(estabelecimento.split(" - ")[0], "cpf_cnpj");
  filter += addFilter(filters.nsu, "nsu");
  filter += `&captura=${filters.saleCapture}&decimals=4`;

  return filter;
};

const addFilter = (condition, param) => {
  if (condition !== "Todos") {
    return `&${param}=${condition}`;
  }
  return "";
};

export const mapCreditCardFlags = (value) => {
  const brandMap = new Map([
    ['<img src="/bandeiras/mastercard.svg" alt="Bandeira"/>', "MASTERCARD"],
    ['<img src="/bandeiras/visa.svg" alt="Bandeira"/>', "VISA"],
    ['<img src="/bandeiras/elo.svg" alt="Bandeira"/>', "ELO"],
    ['<img src="/bandeiras/amex.svg" alt="Bandeira"/>', "AMEX"],
    ['<img src="/bandeiras/hipercard.svg" alt="Bandeira"/>', "HIPERCARD"],
  ]);
  const creditCardFlag = brandMap.get(value);
  return creditCardFlag !== undefined
    ? creditCardFlag
    : ["MASTERCARD", "VISA", "ELO", "AMEX", "HIPERCARD"].includes(value)
    ? value
    : "OUTROS";
};

export const mapCreditCardFlagsForPdf = (value) => {
  const brandMap = new Map([
    ["MASTERCARD", bandeira_master_const],
    ["VISA", bandeira_visa_const],
    ["ELO", bandeira_elo_const],
    ["AMEX", bandeira_amex_const],
    ["HIPERCARD", bandeira_hiper_const],
    ["DINERS", bandeira_dinner_const],
  ]);
  const creditCardFlag = brandMap.get(value);

  return creditCardFlag !== undefined ? creditCardFlag : bandeira_default_const
};

export const zeroPad = (num, places) => {
  return String(num).padStart(places, "0");
};
