import {
  currentUser,
  extratoFindByExtract,
  extratoBalanceAvailable,
  extratoFutureReleases,
  extractPlanRentAndOtherValues
} from '../constants/config'
import axios from 'axios';

const config = currentUser
  ? { headers: { Authorization: currentUser.token } }
  : { headers: { Authorization: "" } }; 

export const getExtractById = (cpf_cnpj, filters) => {
  return axios.get(extratoFindByExtract + cpf_cnpj + filters, config)
}
export const getBalanceAvailable = (cpf_cnpj) => {
  return axios.get(extratoBalanceAvailable + cpf_cnpj, config)
}
export const getFutureReleases = (grupo_id, filters) => {
  return axios.get(extratoFutureReleases + grupo_id + filters, config)
}
export const getPlanRentAndOtherValues = async (filters) => {
  return await axios.get(extractPlanRentAndOtherValues + filters, config)
}
