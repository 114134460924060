
import { generateFilterQuery } from "../../utils/extracts";
import {
  getExtractById,
} from "@/services/extrato-service";

const state = {
  filters: {
    startDate: new Date(
      new Date().getYear() + 1900,
      new Date().getMonth() - 1,
      new Date().getDate()
    ),
    endDate: new Date(),
    tipo_lancamento: { label: "Todos", code: "Todos" },
  },
  extractsList: [],
  isLoadingExtracts: false,
};

const mutations = {
  updateFilters(state, payload) {
    state.filters = { ...state.filters, ...payload };
  },
  setExtractsList(state, payload) {
    state.extractsList = payload
  },
  setIsLoadingExtracts(state, payload) {
    state.isLoadingExtracts = payload
  }
};

const actions = {
  async getExtractsList({ commit, state }, { estabelecimento }) {
    let filters = generateFilterQuery(state.filters)
    let cpf_cnpj = estabelecimento.split(" - ");
    commit("setIsLoadingExtracts", true);
    const response = await getExtractById(cpf_cnpj[0], filters)
    commit("setExtractsList", response.data.data);
    commit("setIsLoadingExtracts", false);
  },
};

const getters = {
  extractFilters: (state) => state.filters,
  extractsList: (state) => state.extractsList,
  isLoadingExtracts: (state) => state.isLoadingExtracts,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
