const state = {
  dismissCountDown: 0,
  showSale: false,
  showSaleFilters: "",
  showSaleToken: ""
};

const mutations = {
  setDismissCountDown(state, newValue) {
    state.dismissCountDown = newValue;
  },
  setShowSaleFilters(state, newValue) {
    state.showSaleFilters = newValue;
  },
  setShowSale(state, newValue) {
    state.showSale = newValue;
  },
  setShowSaleToken(state, newValue) {
    state.showSaleToken = newValue;
  },
};

const actions = {

};

const getters = {
  dismissCountDown: (state) => state.dismissCountDown,
  showSale: (state) => state.showSale,
  showSaleFilters: (state) => state.showSaleFilters,
  showSaleToken: (state) => state.showSaleToken,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
